import React, { useEffect, useRef, useState, MutableRefObject, useCallback } from 'react'
import mapboxgl, { Map } from 'mapbox-gl'
import styled from 'styled-components'
import { MAPBOX_TOKEN } from '../utils/config'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import { Point } from 'utils/common-types'
import { Feature, Point as GeojsonPoint, Polygon as GeojsonPolygon } from 'geojson'
import { IStation } from 'stores/stations'
import { MapPin, PlusSquare, XSquare, Image } from 'react-feather'
import { ActiveVisualizer } from 'App'

mapboxgl.accessToken = MAPBOX_TOKEN

const Draw = new MapboxDraw({
  keybindings: false,
  displayControlsDefault: false,
  modes: Object.assign((MapboxDraw as any).modes, {
    draw_rectangle: DrawRectangle,
  }),
})

const computeBBox = (points: [number, number][]) => {
  let bbox = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY,
  Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY,];
  let vals = points.reduce(function (prev, coord) {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.max(coord[0], prev[2]),
      Math.max(coord[1], prev[3])
    ];
  }, bbox);

  return {
    minLat: vals[0],
    minLon: vals[1],
    maxLat: vals[2],
    maxLon: vals[3]
  }
}

interface IMapProps {
  activeMode: string
  activeVisualizer: ActiveVisualizer
  map: MutableRefObject<Map>
  currentPoint: Point | undefined
  currentPolygon: Feature<GeojsonPolygon> | undefined
  onPointClick?: (lat: number, lng: number) => any
  onPolygonUpdate: (f?: Feature<GeojsonPolygon>) => any
  stations: IStation[]
  onModeChange: (mode: string) => any
  onStationSelect: (station: IStation | undefined) => any
  currentStation: IStation | undefined
  onVisualizationChange: (visualizer: ActiveVisualizer) => any
  since: string
  until: string
  getInterpolationParameters: () => string
}

function MapComponent({
  activeMode,
  activeVisualizer,
  stations,
  currentPolygon,
  currentStation,
  onStationSelect,
  currentPoint,
  onModeChange,
  onPolygonUpdate,
  map,
  onPointClick,
  onVisualizationChange,
  since,
  until,
  getInterpolationParameters,
}: IMapProps) {
  const [mapLoaded, setMapLoaded] = useState(false)
  const mapContainer = useRef<HTMLDivElement>(null)

  const lastMarker = useRef<mapboxgl.Marker | null>(
    null
  ) as MutableRefObject<mapboxgl.Marker | null>

  const clickModeRef = useRef('simple_select')
  const eventsRef = useRef({
    onPointClick,
    onPolygonUpdate,
    onModeChange,
    onStationSelect,
    stations,
    currentStation,
    onVisualizationChange,
  })
  eventsRef.current.onPointClick = onPointClick
  eventsRef.current.onPolygonUpdate = onPolygonUpdate
  eventsRef.current.onModeChange = onModeChange
  eventsRef.current.onStationSelect = onStationSelect
  eventsRef.current.stations = stations
  eventsRef.current.currentStation = currentStation

  const changeMode = (mode: string) => {
    const current = Draw.getMode()
    if (mode === current) return false
    Draw.changeMode(mode)
    eventsRef.current.onModeChange(mode)
    return true
  }

  const setDrawMode = useCallback(() => {
    changeMode('draw_rectangle')
  }, [])

  const resetDraw = useCallback(() => {
    changeMode('simple_select')
    eventsRef.current.onPolygonUpdate(undefined)
    eventsRef.current.onStationSelect(undefined)
  }, [])

  useEffect(() => {
    if (map.current && mapLoaded) {
      changeMode(activeMode)
    }
  }, [map, mapLoaded, activeMode])

  useEffect(() => {
    if (map.current) {
      if (!currentPoint && !currentPolygon) return

      const center: [number, number] = currentStation
        ? [currentStation.lon, currentStation.lat]
        : currentPoint
        ? [currentPoint.lng, currentPoint.lat]
        : [0, 0]

      const offset: [number, number] = [
        currentPoint || currentPolygon ? 255 : 0,
        currentStation ? -200 : 0,
      ]

      const padding = {
        top: 10,
        right: 10,
        left: currentPoint || currentPolygon ? 520 : 10,
        bottom: currentStation ? 420 : 10,
      }

      if (currentPoint)
        map.current.flyTo({
          center,
          offset,
        })

      if (currentPolygon) {
        const coords = (currentPolygon.geometry as any).coordinates[0] as [number, number][]
        map.current.fitBounds([coords[0], coords[coords.length - 3]], {
          padding,
        })
      }
    }
  }, [map, currentPoint, currentPolygon, currentStation])

  useEffect(() => {
    if (mapLoaded && map.current) {
      if (map.current.getLayer('weather-polygon-layer'))
        map.current.removeLayer('weather-polygon-layer')

      if (map.current.getLayer('polygon-layer')) map.current.removeLayer('polygon-layer')
      if (map.current.getSource('polygon-geom')) map.current.removeSource('polygon-geom')

      if (map.current.getLayer('weather-tiles-layer'))
        map.current.removeLayer('weather-tiles-layer')
      if (map.current.getSource('weather-tiles')) map.current.removeSource('weather-tiles')

      if (currentPolygon) {
        map.current.addSource('polygon-geom', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [currentPolygon],
          },
        })
        if (activeVisualizer === ActiveVisualizer.weather) {
          // render tiles
          // add to map
          const { minLat, minLon, maxLat, maxLon } = computeBBox(
            currentPolygon.geometry.coordinates[0] as any
          )
          map.current.addSource('weather-tiles', {
            type: 'image',
            url: `https://sp.lametsy.com.ua/spatial/interpolate?bbox=[${minLat},${minLon},${maxLat},${maxLon}]&${getInterpolationParameters()}`,
            coordinates: [
              [minLat, maxLon],
              [maxLat, maxLon],
              [maxLat, minLon],
              [minLat, minLon],
            ],
          })
          map.current.addLayer({
            id: 'weather-tiles-layer',
            type: 'raster',
            source: 'weather-tiles',
          })
          map.current.addLayer({
            id: 'weather-polygon-layer',
            type: 'line',
            source: 'polygon-geom',
            layout: {},
            paint: {
              'line-color': '#4B63E1',
            },
          })
        } else {
          map.current.addLayer({
            id: 'polygon-layer',
            type: 'fill',
            source: 'polygon-geom',
            layout: {},
            paint: {
              'fill-color': '#4B63E1',
              'fill-opacity': 0.4,
            },
          })
        }
      }
    }
  }, [mapLoaded, currentPolygon, activeVisualizer, since, until, getInterpolationParameters])

  useEffect(() => {
    if (map.current && mapLoaded) {
      if (lastMarker.current) lastMarker.current.remove()
      if (currentPoint) {
        lastMarker.current = new mapboxgl.Marker()
          .setLngLat([currentPoint.lng, currentPoint.lat])
          .addTo(map.current)
      } else {
        lastMarker.current = null
      }
    }
    // eslint-disable-next-line
  }, [mapLoaded, currentPoint])

  useEffect(() => {
    if (map.current && mapLoaded) {
      if (map.current.getLayer('stations-layer')) map.current.removeLayer('stations-layer')
      if (map.current.getSource('stations-points')) map.current.removeSource('stations-points')

      map.current.addSource('stations-points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: stations.map((station) => ({
            type: 'Feature',
            properties: { stationId: station.id },
            geometry: {
              type: 'Point',
              coordinates: [station.lon, station.lat],
            },
          })),
        },
      })

      map.current.addLayer({
        id: 'stations-layer',
        type: 'circle',
        source: 'stations-points',
        paint: {
          'circle-radius': 4,
          'circle-color': '#03c25c',
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 1,
        },
      })
    }
    // eslint-disable-next-line
  }, [mapLoaded, stations])

  useEffect(() => {
    if (map.current && mapLoaded) {
      if (map.current.getLayer('current-station-layer'))
        map.current.removeLayer('current-station-layer')
      if (map.current.getSource('current-station-point'))
        map.current.removeSource('current-station-point')

      map.current.addSource('current-station-point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: currentStation
            ? [
                {
                  type: 'Feature',
                  properties: { stationId: currentStation.id },
                  geometry: {
                    type: 'Point',
                    coordinates: [currentStation.lon, currentStation.lat],
                  },
                },
              ]
            : [],
        },
      })

      map.current.addLayer({
        id: 'current-station-layer',
        type: 'circle',
        source: 'current-station-point',
        paint: {
          'circle-radius': 8,
          'circle-color': '#4B63E1',
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 1,
        },
      })
    }
    // eslint-disable-next-line
  }, [mapLoaded, stations, currentStation])

  useEffect(() => {
    if (!mapContainer.current) return
    map.current = new Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
      center: [26.24307, 50.61393],
      zoom: 7,
      maxZoom: 11,
      minZoom: 2,
    })

    map.current.addControl(new mapboxgl.NavigationControl())
    map.current.addControl(Draw)

    map.current.on('load', () => {
      setMapLoaded(true)

      const updateSelectedPolygon = () => {
        clickModeRef.current = Draw.getMode()
        const { features } = Draw.getAll()
        if (features.length > 0)
          eventsRef.current.onPolygonUpdate(
            features[features.length - 1] as Feature<GeojsonPolygon>
          )
        else eventsRef.current.onPolygonUpdate(undefined)
        Draw.deleteAll()
      }

      map.current.on('draw.create', updateSelectedPolygon)
      map.current.on('draw.delete', updateSelectedPolygon)
      map.current.on('draw.update', updateSelectedPolygon)
      map.current.on('draw.modechange', ({ mode }) => eventsRef.current.onModeChange(mode))
    })

    map.current.on('click', 'stations-layer', (e) => {
      const feature: Feature<GeojsonPoint, { stationId: number }> | undefined =
        e.features && e.features.length > 0 ? (e.features[0] as any) : undefined
      if (!feature) return

      const station = eventsRef.current.stations.find((s) => s.id === feature.properties.stationId)
      if (!station) return

      const coords = feature.geometry.coordinates.slice()
      if (!coords) return

      e.preventDefault()

      while (Math.abs(e.lngLat.lng - coords[0]) > 180) {
        coords[0] += e.lngLat.lng > coords[0] ? 360 : -360
      }

      onStationSelect(station)
    })

    map.current.on('click', (e) => {
      if (Draw.getMode() !== 'simple_select') return
      if (clickModeRef.current !== 'simple_select') {
        clickModeRef.current = 'simple_select'
        return
      }
      if (e.defaultPrevented) return
      const { lat, lng } = e.lngLat
      if (eventsRef.current.onPointClick) eventsRef.current.onPointClick(lat, lng)
    })

    map.current.on(
      'mouseenter',
      'stations-layer',
      () => (map.current.getCanvas().style.cursor = 'pointer')
    )

    map.current.on(
      'mouseleave',
      'stations-layer',
      () => (map.current.getCanvas().style.cursor = '')
    )

    const onKeypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        resetDraw()
      }
    }

    window.document.addEventListener('keydown', onKeypress)
    // eslint-disable-next-line
  }, [])

  return (
    <FullScreenContainer>
      <MapContainer ref={mapContainer} />
      <ModesBox className="mapboxgl-ctrl mapboxgl-ctrl-group" id="draw_rectangle_buttons">
        <ModeButton onClick={setDrawMode} isActive={activeMode === 'draw_rectangle'}>
          <PlusSquare size={23} />
        </ModeButton>
        <ModeButton
          onClick={resetDraw}
          disabled={activeMode !== 'draw_rectangle' && !currentPolygon}
        >
          <XSquare size={23} />
        </ModeButton>
      </ModesBox>

      {currentPolygon && (
        <ActiveVisualizerBox className="mapboxgl-ctrl mapboxgl-ctrl-group">
          <ModeButton
            onClick={() => onVisualizationChange(ActiveVisualizer.stations)}
            isActive={activeVisualizer === ActiveVisualizer.stations}
          >
            <MapPin size={23} />
          </ModeButton>
          <ModeButton
            onClick={() => onVisualizationChange(ActiveVisualizer.weather)}
            isActive={activeVisualizer === ActiveVisualizer.weather}
          >
            <Image size={23} />
          </ModeButton>
        </ActiveVisualizerBox>
      )}
    </FullScreenContainer>
  )
}

export default MapComponent

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const ModesBox = styled.div`
  position: absolute;
  top: 107px;
  right: 10px;
  background: white;
  border-radius: 3px;
  overflow: hidden;
`

const ActiveVisualizerBox = styled(ModesBox)`
  top: 177px;
`

const ModeButton = styled.button<{ isActive?: boolean }>`
  background: ${({ isActive }) => (isActive ? '#4B63E1' : '#fff')} !important;
  color: ${({ isActive }) => (isActive ? '#fff' : '#333')};
  border: none;
  padding: 0;
  margin: 0;

  svg {
    margin-left: 1px;
    margin-top: 2px;
  }

  &:disabled {
    cursor: default;
    svg {
      color: #aaa;
    }
  }
`

const MapContainer = styled(FullScreenContainer)`
  position: absolute;
  overflow: hidden;
`
