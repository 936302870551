import React, { RefObject, useCallback } from 'react'
import styled from 'styled-components'
import niceShadow from 'utils/niceShadow'
import { Search, X } from 'react-feather'
import { Point } from 'utils/common-types'
import { IStation } from 'stores/stations'
import { LoadingStatus } from 'utils/store'
import StationCard from 'components/StationCard'
import Loading from './Loading'
import { Feature } from 'geojson'

export interface IStationsSidePanelProps {
  currentPoint?: Point
  currentPolygon?: Feature
  currentStation?: IStation
  stations: IStation[]
  stationsLoadingStatus: LoadingStatus
  inputRef: RefObject<HTMLInputElement>
  value: string
  onChange: (v: string) => any
  onReset: () => any
  onStationSelect: (staions: IStation) => any
}

export const StationsSidePanel = ({
  currentPoint,
  currentPolygon,
  stations,
  stationsLoadingStatus,
  onReset,
  currentStation,
  onStationSelect,
  inputRef,
  value,
  onChange,
}: IStationsSidePanelProps) => {
  const inputValue = currentPoint
    ? `[${currentPoint.lat.toFixed(5)}, ${currentPoint.lng.toFixed(5)}]`
    : currentPolygon
      ? 'Rectangle selected'
      : value

  const onInputChange = useCallback(
    (e) => !currentPoint && !currentPolygon && onChange(e.target.value),
    [onChange, currentPoint, currentPolygon]
  )

  const isLoading =
    (currentPoint || currentPolygon) &&
    (stationsLoadingStatus === LoadingStatus.pending ||
      stationsLoadingStatus === LoadingStatus.not_loaded)

  const isPointOrPolygon = !!(currentPoint || currentPolygon)

  return (
    <StationsSidePanelWrapper>
      <LocationInputWrapper>
        <Input
          ref={inputRef}
          type="text"
          disabled={isPointOrPolygon}
          placeholder="Search location..."
          onChange={onInputChange}
          value={inputValue}
        />

        {isPointOrPolygon && <X size={24} onClick={onReset} />}
        {!isPointOrPolygon && <Search size={24} />}
      </LocationInputWrapper>

      {(isLoading || stations.length > 0) && (
        <ContentWrapper>
          {isLoading && <Loading title="Loading stations..." />}

          {stations.map((station) => (
            <StationCard
              isActive={station === currentStation}
              onSelect={onStationSelect}
              key={station.id}
              station={station}
            />
          ))}
        </ContentWrapper>
      )}
    </StationsSidePanelWrapper>
  )
}

export default StationsSidePanel

export const StationsSidePanelWrapper = styled.div`
  ${niceShadow}
  position: fixed;
  top: 10px;
  left: 10px;
  width: 500px;
  max-width: calc(100% - 60px);
  border-radius: ${({ theme }) => theme.borderRadius};
  background: white;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  max-height: calc(100vh - 480px);
  overflow: auto;
`

const LocationInputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ccc;

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    color: ${({ theme }) => theme.colors.icon};
  }
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 18px;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.fontUnactive};

  &:focus {
    color: ${({ theme }) => theme.colors.font};
  }
`
