import React from 'react'
import styled from 'styled-components'
import { IStation } from 'stores/stations'

interface IStationCardProps {
  station: IStation
  isActive: boolean
  onSelect: (station: IStation) => any
}

export const StationCard = ({ isActive, station, onSelect }: IStationCardProps) => {
  return (
    <StationCardWrapper isActive={isActive} onClick={() => onSelect(station)}>
      <Title>
        {station.stname}, {station.ctry_full}
      </Title>
      <LatLng>
        <span>lat {station.lat}</span>
        <span>lng {station.lon}</span>
      </LatLng>
    </StationCardWrapper>
  )
}

export default StationCard

const StationCardWrapper = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  ${({ isActive }) => (isActive ? 'background: rgba(0, 0, 0, 0.3) !important;' : '')}

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    border: none;
  }
`

const Title = styled.span``

const LatLng = styled.div`
  display: flex;
  flex-direction: column;
`
