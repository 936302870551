import { Map } from 'mapbox-gl'
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import GlobalStyles from 'global-styles'
import MapComponent from 'components/Map'
import { Point } from 'utils/common-types'
import StationsSidePanel from 'components/StationsSidePanel'
import styled, { ThemeProvider } from 'styled-components'
import theme from 'theme'
import WelcomePopup from 'components/WelcomePopup'
import ChartsPanel, { GoodField } from 'components/ChartsPanel'
import StoreProvider from 'stores/StoreProvider'
import { observer } from 'mobx-react-lite'
import { useRootData } from 'stores'
import { LoadingStatus } from 'utils/store'
import { IStation } from 'stores/stations'
import { Feature, Polygon as GeojsonPolygon } from 'geojson'
import dayjs, { FORMAT } from 'utils/dayjs'
import CalendarPanel from 'components/CalendarPanel'
import ErrorPopup from 'components/ErrorPopup'
import useStickyState from 'utils/useStickyState'
import TitleComponent, { InterpolationState } from 'components/SidePanelForData'
//import {throttle} from 'lodash'

export enum ActiveVisualizer {
  stations = 'stations',
  weather = 'weather',
}
const App = observer(() => {
  const mapRef = useRef<Map>(null) as MutableRefObject<Map>
  const searchRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const [currentPoint, setCurrentPoint] = useState<Point | undefined>(undefined)

  const [currentPolygon, setCurrentPolygon] = useState<Feature<GeojsonPolygon> | undefined>(
    undefined
  )

  const [interpolationState, setInterpolationState] = useState<InterpolationState>({
    gridSize: 32,
    size: 256,
    resX: 256,
    resY: 256,
    proc: 'rbf',
    kernel: 'linear',
    field: 'temp',
    vMul: 1,
    vInc: 0,
    ker_c: 1,
    ker_eps: 1,
    gdal_opt: 'invdist:power=2.0:smoothing=1.0',
    useSize: true,
    colorMap: 'rainbow'
  })
  const onInputChanged = useCallback(
    (name: string, value: any) => {
      setInterpolationState({ ...interpolationState, [name]: value })
    },
    [interpolationState]
  )

  const [currentStation, setCurrentStation] = useState<IStation | undefined>(undefined)
  const [currentSince, setCurrentSince] = useState(dayjs().add(-60, 'day').format(FORMAT))
  const [currentUntil, setCurrentUntil] = useState(dayjs().format(FORMAT))
  const [activeMode, setActiveMode] = useState('simple_select')
  const [activeVisualizer, setActiveVisualizer] = useState<ActiveVisualizer>(
    ActiveVisualizer.weather
  )
  const since = dayjs(currentSince, FORMAT).format('DD.MM.YYYY')
  const until = dayjs(currentUntil, FORMAT).format('DD.MM.YYYY')
  const [selectedFields, setSelectedFields] = useStickyState<GoodField[]>(
    ['min', 'temp', 'max'],
    'defaultSelectedFields'
  )

  const {
    loadStationsByPoint,
    getStationsByPoint,
    getLoadingStatusByPoint,
    getLoadingStatusByPolygon,
    getStationsByPolygon,
    loadStationsByPolygon,
  } = useRootData((store) => store.stationsStore)
  const {
    loadObservationsByStation,
    getObservationsByStation,
    getLoadingStatusByStation,
  } = useRootData((store) => store.weatherStore)

  const stationsLoadingStatus = currentPoint
    ? getLoadingStatusByPoint(currentPoint)
    : currentPolygon
      ? getLoadingStatusByPolygon(currentPolygon)
      : LoadingStatus.not_loaded

  const observationsLoadingStatus =
    (currentStation ? getLoadingStatusByStation(currentStation, since, until) : null) ||
    LoadingStatus.not_loaded

  const stations = currentPoint
    ? getStationsByPoint(currentPoint)
    : currentPolygon
      ? getStationsByPolygon(currentPolygon)
      : []
  const observations = currentStation ? getObservationsByStation(currentStation, since, until) : []

  //const throttled = useRef(throttle((newValue) => console.log(newValue), 1000))

  //useEffect(() => throttled.current(interpolationState), [interpolationState])

  const getInterpolationParameters = useCallback(() => {
    let inst = interpolationState
    let fin = new URLSearchParams()

    fin.append('proc', `${inst.proc}`)
    fin.append('field', `${inst.field}`)
    fin.append('date', `${since}`)
    if (inst.proc === 'rbf') {
      fin.append('ker.c', `${inst.ker_c}`)
      fin.append('ker.eps', `${inst.ker_eps}`)
      fin.append('kernel', `${inst.kernel}`)
    } else if (inst.proc === 'gdal') {
      fin.append('gdal.opt', `${inst.gdal_opt}`)
    }

    fin.append('gridSize', `${inst.gridSize}`)
    if (inst.useSize) {
      fin.append('size', `${inst.size}`)
    } else {
      fin.append('resX', `${inst.resX}`)
      fin.append('resY', `${inst.resY}`)
    }
    fin.append('vMul', `${inst.vMul}`)
    fin.append('vInc', `${inst.vInc}`)
    fin.append('colorMap', `${inst.colorMap}`)

    return fin.toString()
  }, [interpolationState, since])

  const onReset = useCallback(() => {
    setCurrentPoint(undefined)
    setCurrentPolygon(undefined)
    setCurrentStation(undefined)
    setSearch('')
  }, [])
  const onResetforChartsPanel = useCallback(() => {
    setCurrentStation(undefined)
  }, [])
  const onPointClick = useCallback(
    (lat: number, lng: number) => {
      setCurrentPolygon(undefined)
      if (currentPoint && (lat === currentPoint.lat || lng === currentPoint.lng)) return
      setCurrentPoint({ lat, lng })
      setCurrentStation(undefined)
    },
    [currentPoint]
  )

  const onPolygonUpdate = useCallback((f?: Feature<GeojsonPolygon>) => {
    setCurrentPolygon(f)
    if (f) {
      setCurrentPoint(undefined)
      setCurrentStation(undefined)
    }
  }, [])

  const onWelcomeDrawClick = useCallback(() => setActiveMode('draw_rectangle'), [])

  const onWelcomePointClick = useCallback(() => {
    if (!mapRef.current) return
    const center = mapRef.current.getCenter()
    onPointClick(center.lat, center.lng)
  }, [onPointClick])

  const onWelcomeSearchClick = useCallback(() => setTimeout(() => searchRef.current?.focus()), [])

  const onModeChange = useCallback((mode: string) => {
    if (mode === 'draw_rectangle') {
      setCurrentPoint(undefined)
      setCurrentStation(undefined)
      setSearch('')
    }
    setActiveMode(mode)
  }, [])

  useEffect(() => {
    if (currentPoint) loadStationsByPoint(currentPoint)
    // eslint-disable-next-line
  }, [currentPoint])

  useEffect(() => {
    if (currentPolygon) loadStationsByPolygon(currentPolygon)
    // eslint-disable-next-line
  }, [currentPolygon])

  useEffect(() => {
    if (currentStation) loadObservationsByStation(currentStation, since, until)
    // eslint-disable-next-line
  }, [currentStation, since, until])

  useEffect(() => {
    if (activeMode !== 'simple_select') setCurrentPoint(undefined)
  }, [activeMode])

  return (
    <WelcomePopup
      onDrawClick={onWelcomeDrawClick}
      onPointClick={onWelcomePointClick}
      onSearchClick={onWelcomeSearchClick}
    >
      <>
        <DebugInfo>
          {debug({
            activeMode,
            currentPoint,
            currentPolygon,
            observationsLoadingStatus,
            stationsLoadingStatus,
            currentStation,
            currentSince,
            currentUntil,
            selectedFields,
          })}
        </DebugInfo>

        <MapComponent
          map={mapRef}
          activeVisualizer={activeVisualizer}
          currentStation={currentStation}
          onStationSelect={setCurrentStation}
          activeMode={activeMode}
          onPolygonUpdate={onPolygonUpdate}
          currentPoint={currentPoint}
          currentPolygon={currentPolygon}
          onPointClick={onPointClick}
          stations={stations}
          onModeChange={onModeChange}
          onVisualizationChange={setActiveVisualizer}
          since={since}
          until={until}
          getInterpolationParameters={getInterpolationParameters}
        />

        <StationsSidePanel
          currentPoint={currentPoint}
          currentPolygon={currentPolygon}
          onStationSelect={setCurrentStation}
          stations={stations}
          currentStation={currentStation}
          stationsLoadingStatus={stationsLoadingStatus}
          inputRef={searchRef}
          onChange={setSearch}
          onReset={onReset}
          value={search}
        />

        <CalendarPanel
          onChange={(newSince, newUntil) => {
            setCurrentSince(newSince)
            setCurrentUntil(newUntil)
          }}
          since={currentSince}
          until={currentUntil}
        />
        {!currentStation && (
          < TitleComponent
            currentPolygon={currentPolygon}
            onInputChanged={onInputChanged}
            interpolationState={interpolationState}
          />
        )}
        {currentStation && (
          <ChartsPanel
            onReset={onResetforChartsPanel}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            station={currentStation}
            observations={observations}
            observationsLoadingStatus={observationsLoadingStatus}
            since={since}
            until={until}
            currentStation={currentStation}
          />
        )}

        <ErrorPopup />
      </>
    </WelcomePopup>
  )
})

const AppWithContext = () => (
  <StoreProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </StoreProvider>
)

const debug = (o: any) => JSON.stringify(o, null, 2)

const DebugInfo = styled.pre`
  display: none;
  position: fixed;
  z-index: 10000;
  padding: 10px;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.6);
  color: black;
  width: 500px;
  font-size: 12px;
  font-family: monospace;
  line-height: 15px;
  pointer-events: none;
`

export default AppWithContext
