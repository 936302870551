import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { DateRangePicker } from 'react-date-range'
import niceShadow from 'utils/niceShadow'
import dayjs, { FORMAT } from 'utils/dayjs'
import { Calendar, Check } from 'react-feather'

export interface ICalendarPanelProps {
  onChange: (since: string, until: string) => any
  since: string
  until: string
}

const CalendarPanel = ({ since, until, onChange }: ICalendarPanelProps) => {
  const [currentSince, setCurrentSince] = useState(since)
  const [currentUntil, setCurrentUntil] = useState(until)
  const [visibleCalendarSelection, setVisibleCalendarSelection] = useState(false)

  const onClose = useCallback(() => {
    setVisibleCalendarSelection(false)
    onChange(currentSince, currentUntil)
  }, [onChange, currentSince, currentUntil])

  const onOpen = useCallback(() => setVisibleCalendarSelection(true), [])

  return (
    <CalendarPanelWrapper>
      {!visibleCalendarSelection && (
        <CalendarSelectionButton onClick={onOpen}>
          <Calendar size={18} />
          {currentSince} - {currentUntil}
        </CalendarSelectionButton>
      )}
      {visibleCalendarSelection && (
        <CalendarWrapper>
          <DateRangePicker
            ranges={[
              {
                startDate: new Date(currentSince),
                endDate: new Date(currentUntil),
                key: 'selection',
              },
            ]}
            direction="horizontal"
            onChange={(item: any) => {
              setCurrentSince(dayjs(item.selection.startDate).format(FORMAT))
              setCurrentUntil(dayjs(item.selection.endDate).format(FORMAT))
            }}
            minDate={new Date('1973-01-01')}
            maxDate={new Date()}
            months={2}
          />
          <CloseCalendarButton onClick={onClose}>
            <Check size={18} />
            Ok
          </CloseCalendarButton>
        </CalendarWrapper>
      )}
    </CalendarPanelWrapper>
  )
}

const CalendarPanelWrapper = styled.div`
  ${niceShadow}
  position: fixed;
  top: 10px;
  right: 50px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: white;
  overflow: hidden;
`

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const CalendarSelectionButton = styled.button`
  background: white;
  color: #333;
  width: 300px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    margin-right: 10px;
  }
`

const CloseCalendarButton = styled.button`
  background: #4b63e1;
  color: #fff;
  width: 200px;
  height: 40px;
  border: none;
  outline: none;
  text-transform: uppercase;
  border-radius: 20px;
  margin: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    margin-right: 10px;
  }
`

export default CalendarPanel
