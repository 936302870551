import styled from 'styled-components'
import { useState } from 'react'
interface BasicSelectProps {
  name: string
  defaults: string[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export function BasicSelect({ name, defaults, onChange }: BasicSelectProps) {
  const [selectedOption, setSelectedOption] = useState(defaults[0])
  return (
    <Div>
      <Label>{name}</Label>
      <Select
        name={name}
        onChange={(e) => {
          setSelectedOption(e.target.value)
          onChange(e)
        }}
        value={selectedOption}
      >
        {defaults.map((val, i) => (
          <option key={'inp-' + i} value={val}>
            {val}
          </option>
        ))}
      </Select>
    </Div>
  )
}

interface BasicInputProps {
  name: string
  type?: string | 'text'
  value: any
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function BasicInput({ name, type, value, onChange }: BasicInputProps) {
  return (
    <Div>
      <Label>{name}</Label>
      <Input name={name} type={type} value={value} onChange={onChange} />
    </Div>
  )
}

export function BasicCheckbox({ name, value, onChange }: BasicInputProps) {
  const [selectedOption, setSelectedOption] = useState(value)
  return (
    <Div>
      <Label>{name}</Label>
      <InputBox
        name={name}
        type="checkbox"
        checked={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.checked)
          onChange(e)
        }}
      />
    </Div>
  )
}

const Select = styled.select`
  font-size: 1em;
  text-align: center;
  border: 1px solid #d0d0d0;
  border-radius: 0.7em;
  width: 8em;
  margin: 2px 0;
  width: 50%;
`
const Div = styled.div`
  display: flex;
  font-size: 1em;
`
const Input = styled.input`
  width: 50%;
  font-size: 1em;
  border-radius: 0.7em;
  border: 1px solid #d0d0d0;
  text-align: center;
  margin: 2px 0;
  [type="text"]&:focus {
    width: 100%;
    position: absolute;
    margin: 5px 0px;
    right: 0;
  }
`
const InputBox = styled.input`
  width: 50%;
  border-bottom: 1px solid #ccc;
  margin: auto;
  height: 1em;
  font-size: 1em;
`
const Label = styled.label`
  padding: 0.5em 1em;
  width: 50%;
`
