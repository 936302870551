import axios, { AxiosResponse } from 'axios'
import { flow, types, getParent } from 'mobx-state-tree'
import { Point } from 'utils/common-types'
import { API_URL } from 'utils/config'
import { LoadingStatus, IRootStore } from 'utils/store'
import { IStation } from './stations'

export interface IObservationValue {
  val: number
  flag: string
}

export interface IObservationItem {
  id: string
  date: string
  temp: IObservationValue
  dewp: IObservationValue
  min: IObservationValue
  max: IObservationValue
  slp: IObservationValue
  stp: IObservationValue
  wmax: IObservationValue
  wdsp: IObservationValue
  prcp: IObservationValue
}

export const getNameByStation = (station: IStation, since: string, until: string) =>
  `station_${station.uid}_${since}_${until}`

export default types
  .model('WeatherStore', {
    observationsMap: types.map(types.frozen<IObservationItem[]>()),
    observationsLoadingStatusesMap: types.map(types.frozen<LoadingStatus>()),
  })

  .views((self) => ({
    getLoadingStatusByStation: (station: IStation, since: string, until: string) =>
      self.observationsLoadingStatusesMap.get(getNameByStation(station, since, until)) ||
      LoadingStatus.not_loaded,

    getObservationsByStation: (station: IStation, since: string, until: string) =>
      self.observationsMap.get(getNameByStation(station, since, until)) || [],
  }))

  .actions((self) => ({
    loadObservationsByStation: flow(function* (station: IStation, since: string, until: string) {
      const name = getNameByStation(station, since, until)
      self.observationsLoadingStatusesMap.set(name, LoadingStatus.pending)
      try {
        const {
          data: { response, error },
        } = (yield axios.get(
          `${API_URL}/gsod/stations?query=${station.uid}&field=uid&limit=1&since=${since}&until=${until}`
        )) as AxiosResponse<{ response: IObservationItem[]; error?: any[] }>
        getParent<IRootStore>(self).checkErrors(error)
        self.observationsMap.set(name, response || [])
        self.observationsLoadingStatusesMap.set(name, LoadingStatus.success)
      } catch (err) {
        console.error('Failed to fetch observations by station', err)
        self.observationsLoadingStatusesMap.set(name, LoadingStatus.error)
      }
    }),
  }))
