import React from 'react'
import styled from 'styled-components'
import niceShadow from 'utils/niceShadow'
import { X } from 'react-feather'
import { observer } from 'mobx-react-lite'
import { useRootData } from 'stores'

export const ErrorPopup = observer(() => {
  const { errorMessage, visibleError, hideError } = useRootData((store) => store.appStore)

  if (!visibleError) return null

  return (
    <Popup>
      <CloseButton onClick={hideError}>
        <X size={20} />
      </CloseButton>

      <Message>{errorMessage}</Message>
    </Popup>
  )
})

export default ErrorPopup

const Popup = styled.div`
  ${niceShadow}
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 400px;
  max-width: 60%;
  padding: 15px;
  background: #f44336;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: white;
  z-index: 1010;
  font-size: 16px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  color: white;
  background: transparent;
  border: none;
`

const Message = styled.p`
  color: white;
`
