import OutsideClickHandler from 'react-outside-click-handler'
import React, { ReactChild, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import niceShadow from 'utils/niceShadow'
import { StationsSidePanelWrapper } from 'components/StationsSidePanel'
import { Search, X, PlusSquare, MapPin } from 'react-feather'

enum Sections {
  None,
  Search,
  Polygon,
  Point,
}

interface IWelcomePopupProps {
  children: ReactChild
  onSearchClick: () => any
  onDrawClick: () => any
  onPointClick: () => any
}

export const WelcomePopup = ({
  children,
  onDrawClick,
  onPointClick,
  onSearchClick,
}: IWelcomePopupProps) => {
  const [visible, setVisible] = useState(true)
  const [activeSection, setActiveSection] = useState(Sections.None)
  const searchRef = useRef<HTMLButtonElement>(null)
  const drawRef = useRef<HTMLButtonElement>(null)
  const pointRef = useRef<HTMLButtonElement>(null)

  const closePopup = useCallback(() => {
    setVisible(false)
    setActiveSection(Sections.None)
  }, [])

  const useClickHandler = (onClick: () => any) =>
    useCallback(() => {
      onClick()
      closePopup()
    }, [closePopup, onClick])

  const onSearchClickHandler = useClickHandler(onSearchClick)
  const onPointClickHandler = useClickHandler(onPointClick)
  const onDrawClickHandler = useClickHandler(onDrawClick)

  useEffect(() => {
    const elements = new Set<Node>()
    const addSectionElement = (el: Node | null) => {
      if (!el) return
      elements.add(el)
      el.childNodes.forEach(addSectionElement)
    }

    const handleMouseOver = (el: RefObject<HTMLButtonElement>, section: Sections) => {
      if (!el.current) return
      el.current.addEventListener('mouseenter', () => setActiveSection(section))
      addSectionElement(el.current)
    }

    const onMove = (e: MouseEvent) => {
      if (!elements.has(e.target as Node)) setActiveSection(Sections.None)
    }

    handleMouseOver(searchRef, Sections.Search)
    handleMouseOver(drawRef, Sections.Polygon)
    handleMouseOver(pointRef, Sections.Point)

    window.addEventListener('mousemove', onMove)
    return () => window.removeEventListener('mousemove', onMove)
  }, [])

  useEffect(() => {
    const drawContainer = document.querySelector('#draw_rectangle_buttons')
    const copyEl = document.getElementById('visible-draw-suggestion')
    if (!copyEl || !drawContainer) return

    if (activeSection === Sections.Polygon) {
      copyEl.innerHTML = drawContainer.outerHTML
      copyEl.classList.add('visible-suggestion')
    } else {
      copyEl.classList.remove('visible-suggestion')
    }
  }, [activeSection])

  return (
    <PopupWrapper activeSearchSection={activeSection === Sections.Search}>
      {children}
      {visible && (
        <OutsideClickHandler onOutsideClick={closePopup}>
          <Popup>
            <CloseButton onClick={closePopup}>
              <X size={20} />
            </CloseButton>

            <Title>Let's start!</Title>

            <SectionsWrapper>
              <SectionItem ref={searchRef} onClick={onSearchClickHandler}>
                <Search size={40} />
                <span>Search location</span>
              </SectionItem>

              <span>or</span>

              <SectionItem ref={drawRef} onClick={onDrawClickHandler}>
                <PlusSquare size={40} />
                <span>Draw rectangle</span>
              </SectionItem>

              <span>or</span>

              <SectionItem ref={pointRef} onClick={onPointClickHandler}>
                <MapPin size={40} />
                <span>Select point</span>
              </SectionItem>
            </SectionsWrapper>
          </Popup>
          <PopupOverlay
            onClick={closePopup}
            activePointSection={activeSection === Sections.Point}
          />
          <DrawSuggestion id="visible-draw-suggestion" />
        </OutsideClickHandler>
      )}
    </PopupWrapper>
  )
}

export default WelcomePopup

const PopupOverlay = styled.div<{ activePointSection: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out;

  ${({ activePointSection }) =>
    activePointSection
      ? css`
          border: 3px dashed white;
          opacity: 0.7 !important;

          &:after {
            content: 'Click anywhere on the map';
            display: block;
            text-align: center;
            font-size: 40px;
            padding-top: 100px;
            color: white;
          }
        `
      : ''}
`

const DrawSuggestion = styled.div`
  position: absolute;
  z-index: 1020;
  top: 0;
  right: 0;
  display: none;

  &.visible-suggestion {
    display: block;
  }
`

const Popup = styled.div`
  ${niceShadow}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 90%;
  padding: 30px;
  background: #4b63e1;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: white;
  z-index: 1010;
  font-size: 18px;
`

const PopupWrapper = styled.div<{ activeSearchSection: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  ${Popup}:hover + ${PopupOverlay} {
    opacity: 1;
    visibility: visible;
  }

  ${({ activeSearchSection }) =>
    activeSearchSection
      ? css`
          ${StationsSidePanelWrapper} {
            z-index: 1020;
          }
        `
      : ''}
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  color: white;
  background: transparent;
  border: none;
`

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
`

const SectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const SectionItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  text-align: center;
  padding: 10px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;

  svg {
    margin-bottom: 10px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`
