import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import niceShadow from 'utils/niceShadow'
import { API_URL } from 'utils/config'
import {
  ComposedChart,
  XAxis,
  YAxis,
  Line,
  Bar,
  Scatter,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { IStation } from 'stores/stations'
import { LoadingStatus } from 'utils/store'
import { IObservationItem } from 'stores/weather'
import Loading from './Loading'
import { XSquare } from 'react-feather'
import { Feature, Point as GeojsonPoint, Polygon as GeojsonPolygon } from 'geojson'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import { Point } from 'utils/common-types'

export const goodFields = ['prcp', 'dewp', 'wmax', 'wdsp', 'max', 'min', 'temp'] as const
export type GoodField = typeof goodFields[number]

const Draw = new MapboxDraw({
  keybindings: false,
  displayControlsDefault: false,
  modes: Object.assign((MapboxDraw as any).modes, {
    draw_rectangle: DrawRectangle,
  }),
})
interface IChartsPanelProps {
  observationsLoadingStatus: LoadingStatus
  observations: IObservationItem[]
  station: IStation
  selectedFields: GoodField[]
  setSelectedFields: (fields: GoodField[]) => any
  since: string
  until: string
  currentStation: IStation | undefined

  onReset: () => any
}

export const ChartsPanel = ({
  station,
  observations,
  observationsLoadingStatus,
  selectedFields,
  setSelectedFields,
  since,
  until,
  onReset,
}: IChartsPanelProps) => {
  const data = observations.map((item) => ({
    prcp: item.prcp.val,
    dewp: item.dewp.val,
    wmax: item.wmax.val,
    wdsp: item.wdsp.val,
    minTemp: item.min.val,
    maxTemp: item.max.val,
    temp: item.temp.val,
    date: item.date,
  }))




  const URL = `${API_URL}/gsod/stations?query=${station.uid}&field=uid&limit=1&since=${since}&until=${until}`

  const creatInfoDownload = () => {
    fetch(URL)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let creatElement = document.createElement('a');
          creatElement.href = url;
          creatElement.download = `station-${station.uid}-since-${since.replaceAll('.', '-')}-until-${until.replaceAll('.', '-')}.json`;
          creatElement.click();
        });
        //window.location.href = response.url;
      });
  }
  const isLoading =
    observationsLoadingStatus === LoadingStatus.pending ||
    observationsLoadingStatus === LoadingStatus.not_loaded

  const hasField = (name: GoodField) => selectedFields.indexOf(name) >= 0

  const onFieldClick = (name: GoodField) => {
    const newFields = hasField(name)
      ? selectedFields.filter((f) => f !== name)
      : [...selectedFields, name]
    setSelectedFields(newFields)
  }

  return (
    <PanelWrapper>
      {isLoading && <Loading title="Loading weather..." />}
      {!isLoading && (
        <>
          <ChartSettings>
            <p>Active station: {station.stname}</p>
            <p>
              Show fields:{' '}
              {goodFields.map((field) => (
                <FieldSelector
                  isSelected={hasField(field)}
                  disabled={hasField(field) && selectedFields.length < 2}
                  key={field}
                  onClick={() => onFieldClick(field)}
                >
                  {field}
                </FieldSelector>
              ))}
            </p>
            {data.length > 1 && (
              <ButtonDownload name="download">
                <LinkStyled onClick={creatInfoDownload}>
                  Click to download
                </LinkStyled>

              </ButtonDownload>

            )}
            <ModeButton>
              <XSquare size={23}
                onClick={onReset} />
            </ModeButton>
          </ChartSettings>

          {data.length > 0 && (
            <ChartContainer>

              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={data}>
                  <XAxis dataKey="date" />
                  {(hasField('max') || hasField('min') || hasField('temp') || hasField('dewp')) && (
                    <YAxis
                      yAxisId="temperature"
                      label={{ value: '° Celsius', angle: -90, position: 'inside' }}
                    />
                  )}

                  {hasField('prcp') && (
                    <YAxis
                      yAxisId="percipitations"
                      orientation="right"
                      label={{ value: 'Precipitations', angle: -90, position: 'inside' }}
                    />
                  )}

                  {(hasField('wdsp') || hasField('wmax')) && (
                    <YAxis
                      yAxisId="wind_speed"
                      orientation="right"
                      label={{ value: 'Wind speed', angle: -90, position: 'inside' }}
                    />
                  )}

                  {hasField('prcp') && (
                    <Bar yAxisId="percipitations" dataKey="prcp" fill="#413ea0" />
                  )}

                  {hasField('min') && (
                    <Line
                      yAxisId="temperature"
                      dataKey="minTemp"
                      dot={false}
                      connectNulls
                      type="monotone"
                      stroke="#e91e63"
                    />
                  )}

                  {hasField('temp') && (
                    <Line
                      yAxisId="temperature"
                      dataKey="temp"
                      dot={false}
                      connectNulls
                      type="monotone"
                      stroke="#cfd309"
                    />
                  )}

                  {hasField('max') && (
                    <Line
                      yAxisId="temperature"
                      dataKey="maxTemp"
                      dot={false}
                      connectNulls
                      type="monotone"
                      stroke="#009688"
                    />
                  )}

                  {hasField('dewp') && (
                    <Line
                      yAxisId="temperature"
                      dataKey="dewp"
                      dot={false}
                      connectNulls
                      type="monotone"
                      stroke="#607d8b"
                    />
                  )}

                  {hasField('wmax') && (
                    <Scatter yAxisId="wind_speed" dataKey="wmax" fill="#9c27b0" />
                  )}

                  {hasField('wdsp') && (
                    <Scatter yAxisId="wind_speed" dataKey="wdsp" fill="#673ab7" />
                  )}
                  <Tooltip />
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>
          )}

          {data.length < 1 && (
            <ErrorMessage>
              No data :(
              <br />
              You can try other time range
            </ErrorMessage>
          )}
        </>
      )}
    </PanelWrapper>
  )
}

export default ChartsPanel
const ModeButton = styled.button`
  background: white !important;
  color: black};
  border: none;
  cursor: pointer;
  position: absolute;
top: 7px; right:7px;
padding:0px;

`
const ButtonDownload = styled.button`
background-color: DodgerBlue;
border: none;
color: white;
padding: 10px 15px;
cursor: pointer;
font-size: 15px;
position: absolute;
top: 85px; left: 17px;
border-radius:10px;
`
const LinkStyled = styled.a`
text-decoration: none;
color:white;
`
const PanelWrapper = styled.div`
  ${niceShadow}
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 400px;
  background: white;
  z-index: 1000;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 20px;
  display: flex;
`

const ChartSettings = styled.div`
  width: 460px;
  height: 100%;
  line-height: 25px;
`

const ErrorMessage = styled.p`
  text-align: center;
  margin-top: 40px;
  color: #777;
  flex-grow: 1;
  font-size: 20px;
  line-height: 26px;
`

const FieldSelector = styled.button<{ isSelected?: boolean }>`
  background: ${({ isSelected }) => (isSelected ? '#4B63E1' : '#777')};
  color: white;
  margin: 2px;
  border: none;
  outline: none;
  padding: 5px 8px;
  border-radius: 3px;

  &:disabled {
    color: #ccc;
  }
`

const ChartContainer = styled.div`
  height: 100%;
  width: calc(100% - 510px);
  margin-left: auto;
`
