import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    main: 'white',
    font: '#333',
    fontUnactive: '#777',
    icon: '#777',
  },
}

export default theme
