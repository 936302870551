import axios, { AxiosResponse } from 'axios'
import { flow, types, getParent } from 'mobx-state-tree'
import { Point } from 'utils/common-types'
import { API_URL } from 'utils/config'
import { LoadingStatus, IRootStore } from 'utils/store'
import { Feature } from 'geojson'

export interface IStation {
  id: number
  uid: string
  lat: number
  lon: number
  beg: string
  end: string
  stname: string
  ctry_full: string
}

interface INearestResponseItem {
  distance_km: number
  item: IStation
}

export const getNameByPoint = (point: Point) =>
  `point_${point.lat.toFixed(10)}_${point.lng.toFixed(10)}`

export const getNameByPolygon = (polygon: Feature) => 'polygon_' + JSON.stringify(polygon)

const transformStation = (s: IStation) => ({
  ...s,
  id: s.id * 1,
  lat: s.lat * 1,
  lon: s.lon * 1,
})

export default types
  .model('StationsStore', {
    stationsMap: types.map(types.frozen<IStation[]>()),
    stationsLoadingStatusesMap: types.map(types.frozen<LoadingStatus>()),
  })

  .views((self) => ({
    getLoadingStatusByPoint: (point: Point) =>
      self.stationsLoadingStatusesMap.get(getNameByPoint(point)) || LoadingStatus.not_loaded,

    getLoadingStatusByPolygon: (polygon: Feature) =>
      self.stationsLoadingStatusesMap.get(getNameByPolygon(polygon)) || LoadingStatus.not_loaded,

    getStationsByPoint: (point: Point) => self.stationsMap.get(getNameByPoint(point)) || [],

    getStationsByPolygon: (polygon: Feature) =>
      self.stationsMap.get(getNameByPolygon(polygon)) || [],
  }))

  .actions((self) => ({
    loadStationsByPoint: flow(function* (point: Point) {
      const name = getNameByPoint(point)
      self.stationsLoadingStatusesMap.set(name, LoadingStatus.pending)
      try {
        const {
          data: { response, error },
        } = (yield axios.get(
          `${API_URL}/location/nearest?lat=${point.lat}&lon=${point.lng}&count=10`
        )) as AxiosResponse<{ response: INearestResponseItem[]; error?: any[] }>
        getParent<IRootStore>(self).checkErrors(error)
        const items = (response || [])
          .sort((a, b) => a.distance_km - b.distance_km)
          .map((item) => transformStation(item.item))
        self.stationsMap.set(name, items)
        self.stationsLoadingStatusesMap.set(name, LoadingStatus.success)
      } catch (err) {
        console.error('Failed to fetch stations by point', err)
        self.stationsLoadingStatusesMap.set(name, LoadingStatus.error)
      }
    }),

    loadStationsByPolygon: flow(function* (feature: Feature) {
      const name = getNameByPolygon(feature)
      self.stationsLoadingStatusesMap.set(name, LoadingStatus.pending)
      try {
        const {
          data: { response, error },
        } = (yield axios.post(`${API_URL}/gsod/poly?type=geojson`, {
          type: 'FeatureCollection',
          features: [feature],
        })) as AxiosResponse<{ response: IStation[]; error?: any[] }>
        getParent<IRootStore>(self).checkErrors(error)
        const items = (response || []).map(transformStation)
        self.stationsMap.set(name, items)
        self.stationsLoadingStatusesMap.set(name, LoadingStatus.success)
      } catch (err) {
        console.error('Failed to fetch stations by polygon', err)
        self.stationsLoadingStatusesMap.set(name, LoadingStatus.error)
      }
    }),
  }))
