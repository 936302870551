import { types } from 'mobx-state-tree'

import appStore from './app'
import stationsStore from './stations'
import weatherStore from './weather'

export default types
  .model('RootStore', {
    appStore: types.optional(appStore, {}),
    stationsStore: types.optional(stationsStore, {}),
    weatherStore: types.optional(weatherStore, {}),
  })
  .actions((self) => ({
    showError: (message: string, delay?: number) => {
      self.appStore.showError(message, delay)
    },

    checkErrors: (errors?: any[]) => {
      if (errors && errors.length > 0) {
        const lastError = errors[errors.length - 1]
        self.appStore.showError(lastError.message, 3000)
      }
    },
  }))
