import { css } from 'styled-components'

export const centerShadow = css`
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.07), 0 -2px 4px rgba(0, 0, 0, 0.07),
    0 -4px 8px rgba(0, 0, 0, 0.07), 0 -8px 16px rgba(0, 0, 0, 0.07),
    0 -16px 32px rgba(0, 0, 0, 0.07);
`

export default css`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07);
`
