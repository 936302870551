import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  button {
    cursor: pointer;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
  }

  .mapboxgl-marker {
    pointer-events: none;
  }
`
