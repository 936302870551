import React, { useEffect, useState, MutableRefObject, useCallback } from 'react'
import styled from 'styled-components'
import { Feature, Point as GeojsonPoint, Polygon as GeojsonPolygon } from 'geojson'
import niceShadow from 'utils/niceShadow'
import { BasicSelect, BasicInput, BasicCheckbox } from 'components/FormElements'

export interface InterpolationState {
  gridSize: number
  size: number
  resX: number
  resY: number
  proc: string
  kernel: string
  field: string
  vMul: number
  vInc: number
  ker_c: number
  ker_eps: number
  gdal_opt: string
  useSize: boolean
  colorMap: string
}

export interface TitleProps {
  interpolationState: InterpolationState
  currentPolygon?: Feature
  onInputChanged: (name: string, value: any) => void
}

function TitleComponent({ onInputChanged, interpolationState, currentPolygon }: TitleProps) {
  return (
    <ModeTitle>
      {currentPolygon && (
        <FormTitle>
          <FormTitleMain>Interpolation options</FormTitleMain>
          <BasicSelect
            name="proc"
            defaults={['rbf', 'gdal']}
            onChange={(e) => onInputChanged(e.target.name, e.target.value)}
          />

          <BasicSelect
            name="field"
            defaults={['temp', 'min', 'max']}
            onChange={(e) => onInputChanged(e.target.name, e.target.value)}
          />

          {interpolationState.proc === 'rbf' && (
            <BasicSelect
              name="kernel"
              defaults={[
                'linear',
                'cubic',
                'quintic',
                'gaussian',
                'inversemultiquadratic',
                'thinplate',
                'multiquadratic',
              ]}
              onChange={(e) => onInputChanged(e.target.name, e.target.value)}
            />
          )}

          {interpolationState.proc === 'gdal' && (
            <BasicInput
              name="gdal_opt"
              type="text"
              value={interpolationState.gdal_opt}
              onChange={(e) => onInputChanged(e.target.name, e.target.value)}
            />
          )}

          {interpolationState.proc === 'rbf' && (
            <>
              <BasicInput
                name="ker_c"
                type="number"
                value={interpolationState.ker_c}
                onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
              />

              <BasicInput
                name="ker_eps"
                type="number"
                value={interpolationState.ker_eps}
                onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
              />
            </>
          )}
          <LocationSelect>
            <BasicCheckbox
              name="useSize"
              value={interpolationState.useSize}
              onChange={(e) => onInputChanged(e.target.name, Boolean(e.target.checked))}
            />
          </LocationSelect>
          {interpolationState.useSize && (
            <BasicInput
              name="size"
              type="number"
              value={interpolationState.size}
              onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
            />
          )}
          {!interpolationState.useSize && (
            <>
              <BasicInput
                name="resX"
                type="number"
                value={interpolationState.resX}
                onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
              />

              <BasicInput
                name="resY"
                type="number"
                value={interpolationState.resY}
                onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
              />
            </>
          )}

          <BasicInput
            name="gridSize"
            type="number"
            value={interpolationState.gridSize}
            onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
          />

          <BasicInput
            name="vMul"
            type="number"
            value={interpolationState.vMul}
            onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
          />

          <BasicInput
            name="vInc"
            type="number"
            value={interpolationState.vInc}
            onChange={(e) => onInputChanged(e.target.name, Number(e.target.value))}
          />

          <BasicSelect
            name="colorMap"
            defaults={['rainbow', 'binary','viridis', 'plasma', 'inferno', 'magma', 'cividis']}
            onChange={(e) => onInputChanged(e.target.name, e.target.value)}
          />
        </FormTitle>
      )}
    </ModeTitle>
  )
}
export default TitleComponent

const FormTitleMain = styled.p`
  padding: 1em;
`
const ModeTitle = styled.div`
  position: absolute;
  bottom: 1em;
  left: 1em;
  display: flex;
`

const LocationSelect = styled.div`
  margin: 0px;
`
const FormTitle = styled.div`
  ${niceShadow}
  background: white;
  width: 300px;
  background-color: white;
  bottom: 1em;
  border-radius: 0.7em;
  padding: 1em;
  padding-bottom: 2em;
  font-size: 1.1em;
`
