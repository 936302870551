import { Loader } from 'react-feather'
import styled from 'styled-components'

export const Loading = ({ title = 'Loading...' }: { title?: string }) => (
  <LoadingWrapper>
    <Loader size={40} /> {title}
  </LoadingWrapper>
)

const LoadingWrapper = styled.div`
  width: 100%;
  padding: 20px;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`

export default Loading
