import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'

export const FORMAT = 'YYYY-MM-DD'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

export default dayjs
