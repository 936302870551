import { types } from 'mobx-state-tree'

export default types
  .model('AppStore', {
    errorMessage: types.optional(types.string, ''),
    visibleError: types.optional(types.boolean, false),
  })

  .actions((self) => ({
    setErrorMessage: (message: string) => (self.errorMessage = message),
    setErrorVisibility: (visibility: boolean) => (self.visibleError = visibility),
  }))

  .actions((self) => ({
    hideError: () => {
      self.visibleError = false
      setTimeout(() => self.setErrorMessage(''), 200)
    },
  }))

  .actions((self) => ({
    showError(errorMessage: string, delay?: number) {
      delay = delay ? delay : 2000
      self.errorMessage = errorMessage
      setTimeout(() => self.setErrorVisibility(true), 0)
      setTimeout(self.hideError, delay)
    },
  }))
